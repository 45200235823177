<template>
  <div>
    <!-- Table Container Card -->
    <b-card>
      <div class="m-2">
        <b-row class="d-flex justify-content-end pt-md-1">
          <!-- diasble it with form validation -->
          <b-button
            class="d-flex justify-content-center align-items-center"
            variant="primary"
            type="submit"
            href="/categories/create"
            :disabled="isLoading"
            @click="createCategories"
          >
            <span class="text-nowrap">ساخت دسته بندی جدید</span>
            <b-spinner
              v-show="isLoading"
              class="position-absolute"
              small
              type="grow"
            />
          </b-button>
        </b-row>
      </div>

      <!-- Table -->
      <LoadingComponent v-if="loading" />
      <div v-else>
        <div v-if="categories.length > 0">
          <div class="pt-1 pb-1 overflow" v-if="categories.length > 0">
            <vue-good-table
              :sort-options="{ enabled: false }"
              :columns="columns"
              :rows="categories"
              :rtl="false"
              class="w-100"
            >
              <div slot="emptystate" class="w-100 text-center">
                موردی یافت نشد
              </div>
              <template slot="table-row" slot-scope="props" class="mx-auto">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'id'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.id }}</span>
                </span>
                <span v-if="props.column.field === 'title'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.title }}</span>
                </span>
                <span v-if="props.column.field === 'slug'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.slug }}</span>
                </span>
                <span v-if="props.column.field === 'url'" class="text-nowrap">
                  <span class="text-nowrap"><p class="mb-0" dir="ltr">{{ `https://${website_url}/categories/`+props.row.id+"/"+props.row.title.split(' ').join('-') }}</p></span>
                  <br>
                  <b-button @click="doCopy(props.row.title,props.row.id)" variant="primary" :disabled="!props.row.title">کپی متن</b-button>
                </span>
                <span v-if="props.column.field === 'description'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.description }}</span>
                </span>

                <span
                  v-if="props.column.field === 'childs'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{
                    props.row.childs.length > 0 ? "دارد" : "ندارد"
                  }}</span>
                </span>

                <span
                  v-if="props.column.field === 'thumbnail'"
                  class="text-nowrap"
                >
                  <b-avatar
                    :src="props.row.thumbnail"
                    size="3rem"
                    class="text-nowrap"
                  ></b-avatar>
                </span>
                <span
                  v-if="props.column.field === 'status'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.status }}</span>
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  style="width: 100px !important"
                >
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      :right="true"
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        @click="moveToEditCategories(props.row.id)"
                      >
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>ویرایش</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deletePrompt(props.row.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>حذف</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
            </vue-good-table>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalCategories"
            :per-page="perPage"
            first-number
            last-number
            align="left"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0 p-0"
            dir="ltr"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
        <div v-else>
          <h3 class="text-center">موردی یافت نشد</h3>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BSpinner,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import { VueGoodTable } from "vue-good-table";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoadingComponent from "../../components/LoadingComponent.vue";
import copy from 'copy-text-to-clipboard';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BSpinner,
    BFormInput,
    BRow,
    BTable,
    BCol,
    BButton,
    BPagination,
    BDropdownItem,
    BDropdown,
    ToastificationContent,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      website_url: process.env.VUE_APP_BASE_URL.split('https://api.')[1],
      categories: [],
      categoriesLength: 0,
      categoryTray: [],
      totalCategories: 0,
      perPage: 15,
      currentPage: 1,
      columns: [
        {
          label: "شناسه",
          field: "id",
        },
        {
          label: "عنوان",
          field: "title",
        },
        {
          label: "اسلاگ",
          field: "slug",
        },
        {
          label: "آدرس",
          field: "url",
        },
        {
          label: "توضیحات",
          field: "description",
        },
        {
          label: "زیر مجموعه",
          field: "childs",
        },
        {
          label: "عملیات",
          field: "action",
        },
      ],
      formData: {
        title: null,
        slug: null,
        description: null,
        body: null,
        childs: null,
        scope: null
      },
    };
  },
  methods: {
    getCategories() {
      this.loading = true
      axios
        .get("/api/v1/admin/categories", { params: { page: this.currentPage } })
        .then((response) => {
          this.categoriesLength = response.data.data.length;
          this.totalCategories = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          if (this.categoriesLength > 0) {
            this.categoryTray = response.data.data;
            this.categories = [];
            this.categoryTray.forEach((item) => {
              this.categories.push({
                id: item.id,
                title: item.title,
                slug: item.slug,
                description: item.description,
                body: item.body,
                childs: item.childs,
                scope: item.scope,
              });
              // including childs
              if (item.childs.length > 0) {
                for (let index = 0; index < item.childs.length; index++) {
                  const element = item.childs[index];
                  this.categories.push({
                    id: element.id,
                    title: element.title,
                    slug: element.slug,
                    description: element.description,
                    body: element.body,
                    childs: element.childs,
                    scope: element.scope,
                  });
                  // including childs of child
                  if (element.childs.length > 0) {
                    for (let index = 0; index < element.childs.length; index++) {
                      const elementTwo = element.childs[index]

                      this.categories.push({
                        id: elementTwo.id,
                        title: elementTwo.title,
                        slug: elementTwo.slug,
                        description: elementTwo.description,
                        body: elementTwo.body,
                        childs: elementTwo.childs,
                        scope: elementTwo.scope
                      });
                    }
                  }
                }
              }
            });
          }
          this.loading = false;
        });
    },
    deletePrompt(id) {
      this.$bvModal
        .msgBoxConfirm('آیا از حذف مطمئن هستید؟', {
          title: '',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'بله',
          cancelTitle: 'خیر',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value==true) {
            this.deleteCategories(id)
          }
        })
    },
    deleteCategories(id) {
      axios
        .post(`/api/v1/admin/categories/${id}/delete`)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "دسته بندی حذف شد",
              icon: "CheckIcon",
              text: "دسته بندی با موفیقت حذف شد.",
              variant: "info",
            },
          });
          this.getCategories();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    moveToEditCategories(id) {
      router.push(`/categories/${id}/update`);
    },
    createCategories() {
      this.isLoading = true;
      axios
        .post("/api/v1/admin/categories/create", this.formData)
        .then((response) => {
          this.getCategories();
          this.formData.name = "";
          this.formData.slug = "";

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "دسته بندی اضافه شد",
              icon: "CheckIcon",
              text: "دسته بندی با موفیقت اضافه شد",
              variant: "success",
            },
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    doCopy(text,id) {
      copy(`https://${process.env.VUE_APP_BASE_URL.split('https://api.')[1]}/categories/` + id + "/" + text.split(' ').join('-'));
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `آدرس مورد نظر کپی شد`,
          icon: "XOctagonIcon",
          text: `https://${process.env.VUE_APP_BASE_URL.split('https://api.')[1]}/categories/` + id + "/" + text.split(' ').join('-'),
          variant: "success",
        },
      });
    },
  },
  mounted() {
    this.getCategories();
    this.$watch("currentPage", (newCurrentPage) => {
      this.getCategories();
    });
  },
  unmounted() {
    this.$watch("currentPage");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
  width: 90px;
}
</style>